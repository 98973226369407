// JavaScript Document

//VARIABLES GLOBALES //////////////////////////////////////////////
var bH, bW 				= null;
var lan;
var breakpoint_sm 		= 0;
var breakpoint_md 		= 640;
var breakpoint_lg 		= 1024;
var breakpoint_xlg 		= 1200;
var breakpoint_xxlg		= 1440;
var dialog;
var current_ref;



jQuery(function($){
	
	///////////////////////////////////////////////////////////////
	//FUNCIONES GLOBALES //////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	// Browser size ***********************************************
	function browserSize(){
		bW = $(window).width();
		bH = $(window).height();
	}


	// Background images ******************************************
	function bkg() {
		$("[data-bkg]").each(function() {
			$(this).css("background-image", "url('" + $(this).data("bkg") + "')");
		});
	}


	// Multiple background images *********************************
	function bkgmulti() {
		$("[bkgmulti]").each(function() {
			var bkgsm =  $(this).data("bkg-sm");
			var bkgmd =  $(this).data("bkg-md");
			var bkglg =  $(this).data("bkg-lg");

			var bkgimg = bkgsm;
			
			if ( bW < breakpoint_sm ) {
				bkgimg = bkgsm;
			}
			
			if ( bW >= breakpoint_sm && bW < breakpoint_md ) {
				bkgimg = bkgmd;
			}

			if ( bW >= breakpoint_md ) {
				bkgimg = bkglg;
			}

			$(this).css("background-image", "url('" + bkgimg + "')");
		});
	}


	// Language ***************************************************
	function languaje() {
		lan = $("html").attr("lang");
	}


	// Menu Buider ***********************************************
	function menuBuilder(objId) {
		var menu;

		if ( bW < breakpoint_lg) {
			//-- Accordion menu
			$(objId).removeClass("dropdown");
			$(objId).addClass("vertical");
			menu = new Foundation.AccordionMenu($(objId), {
				slideSpeed: 250,
				multiExpand: false
			});
		} else {
			//-- Dropdown menu
			$(objId).removeClass("vertical");
			$(objId).addClass("dropdown");
			menu = new Foundation.DropdownMenu($(objId));
		}

	}


	// Video player ***********************************************
	function videoPlayer(cv) {
		
		var videoTitle = $(cv).data("title");
		var videoDesc = $(cv).data("desc");
		var videoFile = $(cv).data("media");

		if ( typeof videoTitle === "undefined" || videoTitle === "" ) {
			videoTitle = " ";
		}

		if ( typeof videoDesc === "undefined" || videoDesc === "" ) {
			videoDesc = " ";
		}

		var labelVideoNotFound = "Video not found";
		if (lan !== "en") {
			labelVideoNotFound = "Video no encontrado";
		}

		if ( typeof videoFile === "undefined" || videoFile === "" ) {
			dialog = "<h2>" + labelVideoNotFound + "</h2>";
		} else {
			var mediaFormat = videoFile.slice(-4);
			var mimeType;

			switch(mediaFormat) {
				case ".ogg":
					mimeType = "video/ogg";
					break;
				case "webm":
					mimeType = "video/webm";
					break;
				default:
					mimeType = "video/mp4";
					break;
			}

			dialog ='<div id="video">' +
						'<button class="mfp-close cg-icon-close"></button>' +
						'<video id="media" controls preload="true">' +
							'<source src="' + videoFile + '" type="' + mimeType + '"/>' +
							'Your browser does not support the audio tag.' +
						'</video>' +
						'<figcaption>' +
							'<div class="mfp-bottom-bar">' +
								'<div class="mfp-title">' +
									'<h5 class="title">' + videoTitle + '</h5>' + '<small>' + videoDesc +'</small>' +
								'</div>' +
							'</div>' +
						'</figcaption>' +
					'</div>';
		}

		$.magnificPopup.open({
			items: {
				src: dialog,
				type: 'inline'
			},
			preloader: true,
			callbacks: {
				beforeOpen: function() {
					$("body").toggleClass("no-scroll");
				},
				open: function() {
					var v = document.getElementById("media");
					var vW, vH;
					v.addEventListener( "loadedmetadata", function () {  // function (event)
						vW = this.videoWidth;
						vH = this.videoHeight;
						$( "#video" )
							//.css( "width", vW + "px" )
							.position({ my:"center", at:"center", of:"window" });
					}, false );

					$("#video").animate({ opacity: 1 }, 400);
				},
				close: function() {
					$("body").toggleClass("no-scroll");
				}
			}
		});
	}


	// Referencias ************************************************
	var ref;

	function references ( ref ) {
		var r_title = $(ref).find("a").data("title");
		var r_desc = $(ref).find("a").data("desc");
		var r_img = "";
		var img = $(ref).find("a").data("img");

		$(ref).find(".icon").append('<span class="pointer"></span>');

		if ( img ) {
			r_img = '<img src="' + img + '" alt="Logo">' ;
		}
		
		var r_dropdown =	'<div class="reference-dropdown">' + 
								'<div class="inner">' + r_img +
									'<p class="title">' + r_title + '</p>' +
									'<div class="desc">' + r_desc + '</div>' +
								'<div>' + 
								//'<div class="pointer"></div>' +
							'<div>';

		$(ref).prepend(r_dropdown);
		//$("body").append(r_dropdown);
	}

	function reference_close ( ref ) {
		$(".reference-dropdown").remove();
		$(ref).removeClass("active");
		$(ref).find(".pointer").remove();
	}


	// Soundcloud Player height **********************************
	function soundcloudH () {
		// Default height value
		var scpH = bH - $(".multiSounds__sound").height();
		var adsH = 0;

		// Ads columns height
		if (bW >= breakpoint_md && bW < breakpoint_lg ) {
			var scpH = $("#soundcloudPlayer").width();
		}

		if (bW >= breakpoint_lg) {
			$(".ads-column").find(".ad-block").each(function(){
				adsH = adsH + $(this).outerHeight(true);
				// console.log(adsH);
			});
			adsH = adsH + $(".donar-large").outerHeight(true) - $(".podcast-info").outerHeight(true);

			if (adsH <= 400) {
				scpH = 400;
			} else {
				scpH = adsH;
			}
		}

		// console.log(scpH);
		$("#soundcloudPlayer").css("height", scpH + "px");
	}




	///////////////////////////////////////////////////////////////
	//FUNCIONES ON DOCUMENT READY /////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(document).ready(function() {

		browserSize();

		languaje();

		bkg();

		bkgmulti();

		// Main menu **********************************************
		menuBuilder("#main-menu");


		// Open / Close Main Menu
		$("#menu-toggle").click(function(){
			$(".wrapper-inner").toggleClass("active");
			$("#search-toggle, #search-form").removeClass("active");
		});


		// Open/Close Quick Search ********************************
		function toggleSearch() {
			$("#search-toggle, #search-form").toggleClass("active");
			$(".wrapper-inner").removeClass("active");
			$("#search-box").val("");
			event.stopPropagation();
		}

		$("#search-toggle").click(function() {
			toggleSearch();
		});

		$("#search-box").focus(function() {
			if ( ! $("#search-form").hasClass("active") ) {
				toggleSearch();
			}
		});

		$(document).click(function(event) { 
			if ( $("#search-form").hasClass("active") ) {
				if(!$(event.target).closest("#search-form").length) {
					toggleSearch();
				}
			}
		});


		// Most Recent items - Carousel
		$(".carrusel").slick({
			infinite: false,
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			arrows: true,
			dots: true,
			responsive: [
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: breakpoint_md,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: breakpoint_lg,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: breakpoint_xlg,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5
					}
				}
			]
		});


		// Form validation ****************************************
		// $("#msg .ok").click(function() {
		// 	$("#contact-form").trigger("reset").attr("data-send", "none");
		// 	$("#contact-form #fname").focus();
		// });

		// $("#msg .error").click(function() {
		// 	$("#contact-form").attr("data-send", "none");
		// 	$("#contact-form #fname").focus();
		// });


		// Image Gallery *********************************
		$(".image-gallery").each(function(){
			$(this).magnificPopup({
				tLoading: 'Loading image #%curr%...',
				delegate: ".thum, .thumbnail",
				type: 'image',
				closeMarkup: '<button class="mfp-close cg-icon-close"></button>',
				gallery:{
					enabled:true,
					preload: [0,1],
					navigateByImgClick: true,
					arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
					tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
				},
				image: {
					tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
					titleSrc: function(item) {
						return '<h5 class="title">' + item.el.data("title") + '</h5>' + '<small>' + item.el.data("desc") +'</small>';
					}
				}
			});
		});

		// Image Gallery Single *********************************
		$(".image-gallery-single").each(function(){
			$(this).magnificPopup({
				tLoading: 'Loading image #%curr%...',
				delegate: "a",
				type: 'image',
				closeMarkup: '<button class="mfp-close cg-icon-close"></button>',
				//arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
				//tCounter: '<span class="mfp-counter">%curr% of %total%</span>',
				image: {
					tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
					titleSrc: function(item) {
						return '<h5 class="title">' + item.el.data("title") + '</h5>' + '<small>' + item.el.data("desc") +'</small>';
					}
				}
			});
		});


		// // Form submit confirmationn
		// var submitStatus = $("body").data("submit");
		// if ( submitStatus == "success" || submitStatus == "error" ) {
		// 	$('#confirmation').foundation('open');
		// }

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW LOAD ////////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(window).load(function() {

		// Open reference PopUp
		$(".reference").click(function(){
			current_ref = $(this);
			
			if ( !$(current_ref).hasClass("active") ) {
				references( current_ref );
				$(current_ref).addClass("active");
				$(current_ref).find(".reference-dropdown").position({
					my: "left bottom",
					at: "left top",
					of: $(current_ref),
					collision: "fit none",
					within: $(current_ref).closest(".row"),
					using: function(pos, ui) {
						// var topOffset = pos.top;
						// var leftOffset = pos.left;
						//console.log(pos.top)
						// if ( $(this).css("top") < topOffset ) {
						// 	$(this).addClass("hfit");
						// }
						if ( pos.left < 0 ) {
							$(this).addClass("vfit");
						}
						// pos.top = 0;
						// pos.left = 0;
						// ui.element{};
						// ui.target{};
						// ui.important{};
						//var topOffset = $( this ).css( pos ).offset().top;
						// if ( topOffset < 0 ) {
						// 	$( this ).css( "top", pos.top - topOffset );
						// }
					}
				});
				$(".reference-dropdown").find(".pointer").position({
					my: "center bottom",
					at: "center top",
					of: $(current_ref).find(".icon"),
					collision: "none"
				});
			} else {
				reference_close ( current_ref );
			}

			$(current_ref).mouseleave(function(){
				reference_close ( current_ref );
			});
		});


		// // Video player *******************************************
		$("[data-dialog=video]").click(function() {
			var sv = $(this);
			videoPlayer(sv);
		});


		// Soundcloud Player height **********************************
		$("#soundcloudPlayer", soundcloudH());

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW RESIZE //////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(window).resize(function() {
		
		browserSize();

		bkgmulti();

		reference_close ( current_ref );


		// Main menu **********************************************
		$("#main-menu").foundation('destroy');
		menuBuilder("#main-menu");
		$(".wrapper-inner").removeClass("active");


		// Close Search *******************************************
		$("#search-toggle, #search-form").removeClass("active");


		// Soundcloud Player height *******************************
		$("#soundcloudPlayer", soundcloudH());



		// $.throttle( 250, function() {
		// });

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW SCROLL //////////////////////////////////
	///////////////////////////////////////////////////////////////
	$( window ).scroll(function() {
		reference_close ( current_ref );
	});

	
	
	///////////////////////////////////////////////////////////////
	//FUNCIONES ON DEVICE ORIENTATION CHANGE //////////////////////
	///////////////////////////////////////////////////////////////
	//$( window ).on( "orientationchange", function() {
	//});





	// $(document).mouseup(function (e) {
	//     var container = $(".menu.open");

	//     if (!container.is(e.target) // if the target of the click isn't the container...
	//         && container.has(e.target).length === 0) // ... nor a descendant of the container
	//     {
	// 		$("body").removeClass("noscroll");
	// 		$(".menu").removeClass("open");
	//     }
	// });
});











